import { BaseClient } from "../../base-client";
import { Offering, OfferingCategory } from "../models";

interface IOfferingClient {
  getOffering: (offeringId: string) => Promise<Offering>;
  getOfferings: (category: OfferingCategory) => Promise<Offering[]>;
}

class OfferingClient extends BaseClient implements IOfferingClient {
  constructor(private baseUrl: string) {
    super(baseUrl);
  }

  async getOffering(offeringId: string): Promise<Offering> {
    return (await this.httpClient.get(`/v1/offering/${offeringId}`)).data;
  }
  async getOfferings(category: OfferingCategory): Promise<Offering[]> {
    return (
      await this.httpClient.get("/v1/offerings", {
        params: {
          category,
        },
      })
    ).data;
  }
}

export const getOfferingClient = (baseUrl: string) =>
  new OfferingClient(baseUrl);
