import { PageContent } from "@netgreen/core-ui";
import {
  DuplicateIcon,
  Icon,
  Spinner,
  Table,
  Text,
  toaster,
} from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { User } from "../clients/admin-client";
import { useUsers } from "../hooks/useUsers";


export const Users = () => {
  const { data: users, isLoading: usersLoading } = useUsers();
  const navigate = useNavigate();

  return (
    <PageContent pageTitle="Users">
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>AML Status</Table.TextHeaderCell>
          <Table.TextHeaderCell>Account Setup</Table.TextHeaderCell>
          <Table.TextHeaderCell>Copy ID</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {usersLoading && <Spinner />}
          {!usersLoading &&
            users &&
            users.users.map((user: User) => (
              <Table.Row key={user.id} onClick={() => navigate(`/user/${user.id}/details`)}>
                <Table.TextCell>{user.email}</Table.TextCell>
                <Table.TextCell>
                  {user.name} {user.lastName}
                </Table.TextCell>
                <Table.TextCell>{user.amlStatus}</Table.TextCell>
                <Table.TextCell>
                  {String(user.isAccountSetup ?? "false")}
                </Table.TextCell>
                <Table.TextCell>
                  <Icon
                    icon={DuplicateIcon}
                    onClick={() => {
                      navigator.clipboard.writeText(user.id);
                      toaster.success("Copied to clipboard");
                      setTimeout(() => {
                        toaster.closeAll();
                      }, 2000);
                    }}
                  />
                </Table.TextCell>
              </Table.Row>
            ))}
          {!usersLoading && !users && <Text>No Users</Text>}
        </Table.Body>
      </Table>
    </PageContent>
  );
};
