

import { useQuery } from "@tanstack/react-query";
import { IGetDocumentsResponse, IGetOfferingDocumentsResponse, getDocusignDocuments, getDocusignDocumentsForOffering } from "../clients/admin-client";

export const useOfferingDocusignDocuments = (offeringId: string) => {
    return useQuery<IGetOfferingDocumentsResponse>(
      [`offering-docusign-${offeringId}`],
      () => getDocusignDocumentsForOffering(offeringId)
    );
  };
  

  export const useDocusignDocuments = () => {
    return useQuery<IGetDocumentsResponse>(
      [`docusign-documents`],
      () => getDocusignDocuments()
    );
  };
  