import { useCurrentCognitoUser } from "@netgreen/auth";
import { Avatar, Icon, Pane, Text, UserIcon } from "evergreen-ui";
import { useNavigate } from "react-router-dom";

interface ProfileRowProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  showNav: Function;
}
export const ProfileRow = ({ showNav }: ProfileRowProps) => {
  const { data: user } = useCurrentCognitoUser();
  const navigate = useNavigate();
  const name = `${user?.name} ${user?.family_name}`;
  return (
    <Pane
      display="flex"
      alignItems="center"
      flexDirection="row"
      onClick={() => {
        navigate(`/`);
        showNav(false);
      }}
    >
      {user ? (
        <Avatar name={name} size={50} />
      ) : (
        <Icon icon={UserIcon} size={48} />
      )}

      <Pane marginLeft="1rem" display="flex" flexDirection="column">
        <Text fontWeight="bold">{name}</Text>
      </Pane>
    </Pane>
  );
};
