import styled from '@emotion/styled';
import {
  ButtonWithAnalytics,
  ButtonWithAnalyticsWithRef,
  ProgressBar,
  breakpoints,
} from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import {
  EmptyState,
  ExchangeIcon,
  Heading,
  Icon,
  Pane,
  Popover,
  Position,
  SearchIcon,
  SelectField,
  Spinner,
  Table,
  Text,
  toaster,
} from 'evergreen-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cancelTradeForUser } from '../clients/admin-client';
import { Offering } from '../clients/offering-client';
import { useTrades } from '../hooks';
import { CancelReasons } from '../models/cancelReasons';

const SummaryCardsPane = styled(Pane)`
  flex-direction: column;
  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    gap: 0.5rem;
  }
`;

const SummaryCard = styled(Pane)`
  width: 100%;
  margin-bottom: 1.5rem;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 200px;
  }
`;

export const InvestmentView = ({ offering }: { offering?: Offering }) => {
  const { data, isLoading } = useTrades(offering?.id ?? '');
  const [cancelReason, setCancelReason] = useState<string>(
    Object.keys(CancelReasons)[0]
  );
  const navigate = useNavigate();
  const { mutate: cancelInvestmentMutation, isLoading: isCancelLoading } =
    useMutation(cancelTradeForUser, {
      onSuccess: () => {
        toaster.success('Success', {
          description: 'Successfully updated your investor status',
        });
      },
      onError: (error: Error) => {
        if (axios.isAxiosError(error)) {
          if ((error.response?.data as any).error) {
            toaster.danger('Error cancelling', {
              description: String((error.response?.data as any).error),
            });
          }
        } else {
          toaster.danger('Error cancelling', {
            description: String(error),
          });
        }
      },
    });
  const investmentInFlight =
    data
      ?.filter((trade) => trade.status === 'CREATED')
      .map((trade) => trade.total)
      .reduce((a, b) => a + b, 0) ?? 0;
  const investmentInEscrow =
    data
      ?.filter((trade) => trade.status === 'FUNDED')
      .map((trade) => trade.total)
      .reduce((a, b) => a + b, 0) ?? 0;

    const getDocumentStatus = (docusignDocuments?: {eSignStatus: string}[]): string => {
      if (!docusignDocuments || docusignDocuments.length === 0) {
        return 'N/A'
      }
      for (const document of docusignDocuments) {
        if (document.eSignStatus === 'NOTSIGNED') {
          return 'NOT SIGNED'
        }
      }
      return 'SIGNED';
    }

    const displayDate = (date: string): string => {
      const d = new Date(Date.parse(date));
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    }
      
  return (
    <>
      <Pane marginBottom="1rem">
        <ProgressBar
          progress={
            ((investmentInEscrow + investmentInFlight) /
              (offering?.target_amount ?? 0)) *
            100
          }
        />
        <Text>
          ${(investmentInEscrow + investmentInFlight).toLocaleString()} / $
          {(offering?.target_amount ?? 0).toLocaleString()}
        </Text>
      </Pane>

      <SummaryCardsPane display="flex" marginBottom="1rem">
        <SummaryCard
          elevation={3}
          height={120}
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          paddingY="1.5rem"
          borderRadius="10px"
        >
          <Heading size={300}>Invested in Escrow</Heading>
          <Text size={600} fontWeight="bold">
            ${investmentInEscrow.toLocaleString()}
          </Text>
        </SummaryCard>
        <SummaryCard
          elevation={3}
          height={120}
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          paddingY="1.5rem"
          borderRadius="10px"
        >
          <Heading size={300}>Investments In Flight</Heading>
          <Text size={600} fontWeight="bold">
            ${investmentInFlight.toLocaleString()}
          </Text>
        </SummaryCard>
      </SummaryCardsPane>

      <Pane marginBottom="1rem">
        <ButtonWithAnalytics
          analyticName="createTrade"
          onClick={() => navigate('create-trade')}
          appearance="primary"
        >
          Create Investment
        </ButtonWithAnalytics>
      </Pane>

      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
          <Table.TextHeaderCell>Fee</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell>Docusign</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell width=".5rem">Cancel Investment</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {isLoading && (
            <Pane
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="10rem"
            >
              <Spinner />
            </Pane>
          )}
          {!isLoading && (!data || (data && data.length === 0)) && (
            <EmptyState
              background="light"
              title="No Investments in this Offering"
              orientation="horizontal"
              icon={<SearchIcon color="#C1C4D6" />}
              iconBgColor="#EDEFF5"
              description="No investments have been found for this offering"
            />
          )}
          {!isLoading &&
            data
              ?.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
              .map((trade) => {
                return (
                  <Table.Row key={trade.tradeId}>
                    <Table.TextCell>{displayDate(trade.date)}</Table.TextCell>
                    <Table.TextCell>
                      ${trade.total.toLocaleString()}
                    </Table.TextCell>
                    <Table.TextCell>
                      ${trade.platformFee.toLocaleString()}
                    </Table.TextCell>
                    <Table.TextCell>{trade.status}</Table.TextCell>
                    <Table.TextCell width="2">{getDocumentStatus(trade.tradeDocuments)}</Table.TextCell>
                    <Table.TextCell width="2">{trade.email}</Table.TextCell>
                    <Table.TextHeaderCell textAlign="right">
                      {(trade.status === 'FUNDED' ||
                        trade.status === 'CREATED') && (
                        <Pane
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Popover
                            position={Position.LEFT}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            content={({ close }) => (
                              <Pane
                                minHeight="10rem"
                                padding="1rem"
                                width="100%"
                                maxWidth="40rem"
                              >
                                <Pane marginBottom="1rem">
                                  <Text>
                                    Are you sure you want to cancel{' '}
                                    {trade.email} investment in {offering?.name}
                                    ?
                                  </Text>
                                </Pane>
                                <Pane marginBottom="1rem">
                                  <SelectField
                                    name="reason"
                                    label="Reason"
                                    width="100%"
                                    required
                                    value={cancelReason}
                                    onChange={(event) =>
                                      setCancelReason(event.target.value)
                                    }
                                  >
                                    {Object.keys(CancelReasons).map(
                                      (k: string) => (
                                        <option key={k} value={k}>
                                          {CancelReasons[k]}
                                        </option>
                                      )
                                    )}
                                  </SelectField>
                                </Pane>
                                <ButtonWithAnalytics
                                  analyticName="ConfirmCancelInvestmentBtn"
                                  appearance="destructive"
                                  width="100%"
                                  disabled={isCancelLoading}
                                  marginBottom="1rem"
                                  onClick={() => {
                                    cancelInvestmentMutation({
                                      userId: trade.userId,
                                      tradeId: trade.tradeId,
                                      reason: cancelReason,
                                    });
                                    close();
                                  }}
                                >
                                  Confirm Cancellation
                                </ButtonWithAnalytics>
                                <ButtonWithAnalytics
                                  analyticName="CancelInvestmentCloseBtn"
                                  appearance="default"
                                  width="100%"
                                  disabled={isCancelLoading}
                                  onClick={() => {
                                    close();
                                  }}
                                >
                                  Close
                                </ButtonWithAnalytics>
                              </Pane>
                            )}
                          >
                            {({ getRef, isShown, toggle }) => (
                              <ButtonWithAnalyticsWithRef
                                analyticName="CancelInvestmentBtn"
                                appearance="destructive"
                                disabled={isShown && isCancelLoading}
                                width="100%"
                                onClick={() => {
                                  toggle();
                                }}
                                ref={getRef as any}
                                isActive={isShown}
                              >
                                <Icon icon={ExchangeIcon} />
                              </ButtonWithAnalyticsWithRef>
                            )}
                          </Popover>
                        </Pane>
                      )}
                    </Table.TextHeaderCell>
                  </Table.Row>
                );
              })}
        </Table.Body>
      </Table>
    </>
  );
};
