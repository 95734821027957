import { environment } from '../../../environments/environment';
import { getAdminClient } from '../client';
import {
  IAdminGetUserResponse,
  IAdminGetUsersResponse,
  IAdminOfferingGetUsersResponse,
  IAdminOfferingTrades,
  ICreateAccountRequest,
  ICreateTradeForUserRequest,
  IGetDocumentsResponse,
  IGetOfferingDocumentsResponse,
  IUserAccount,
} from '../models';

const baseUrl = `${environment.apiBaseUrl}/admin`;

export const getTradesForOffering = async (
  offeringId: string
): Promise<IAdminOfferingTrades[]> => {
  const client = getAdminClient(baseUrl);
  return client.getTradesForOffering(offeringId);
};

export const getOfferingUsers = async (
  offeringId: string
): Promise<IAdminOfferingGetUsersResponse> => {
  const client = getAdminClient(baseUrl);
  return client.getOfferingUsers(offeringId);
};

export const getUsers = async (): Promise<IAdminGetUsersResponse> => {
  const client = getAdminClient(baseUrl);
  return client.getUsers();
};

export const getUser = async (
  userId: string
): Promise<IAdminGetUserResponse> => {
  const client = getAdminClient(baseUrl);
  return client.getUser(userId);
};

export const cancelTradeForUser = async ({
  userId,
  tradeId,
  reason,
}: {
  userId: string;
  tradeId: string;
  reason: string;
}): Promise<void> => {
  const client = getAdminClient(baseUrl);
  return client.cancelTradeForUser(userId, tradeId, reason);
};

export const createTradeForUser = async (
  data: ICreateTradeForUserRequest
): Promise<IAdminGetUsersResponse> => {
  const client = getAdminClient(baseUrl);
  return client.createTradeForUser(data.offeringId, data.data);
};

export const createAccount = async ({
  data,
  userId,
}: {
  data: ICreateAccountRequest;
  userId: string;
}): Promise<IUserAccount> => {
  const client = getAdminClient(baseUrl);
  return client.createAccount(data, userId);
};

export const getDocusignDocumentsForOffering = async (
  offeringId: string
): Promise<IGetOfferingDocumentsResponse> => {
  const client = getAdminClient(baseUrl);
  return client.getDocusignDocumentsForOffering(offeringId);
};

export const getDocusignDocuments =
  async (): Promise<IGetDocumentsResponse> => {
    const client = getAdminClient(baseUrl);
    return client.getDocusignDocuments();
  };

export const attachDocusignToOffering = async ({
  offeringId,
  templateId,
  templateName,
}: {
  offeringId: string;
  templateId: string;
  templateName: string;
}): Promise<void> => {
  const client = getAdminClient(baseUrl);
  return client.attachDocusignToOffering({
    offeringId,
    templateId,
    templateName,
  });
};

export const deleteDocusignFromOffering = async ({
  templateId,
  offeringId,
}: {
  templateId: string;
  offeringId: string;
}): Promise<void> => {
  const client = getAdminClient(baseUrl);
  return client.deleteDocusignFromOffering({ templateId, offeringId });
}
