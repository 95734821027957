import {
  AddressForm,
  AddressFormModel,
  ButtonWithAnalytics,
  defaultAddressModel,
} from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import {
  Checkbox,
  Heading,
  Pane,
  SelectField,
  Spinner,
  TextInputField,
  toaster,
} from 'evergreen-ui';
import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createAccount } from '../clients/admin-client';
import { useUser } from '../hooks/useUser';

export interface AccountFormModel extends AddressFormModel {
  accountName: string;
  type: string;
}

export const CreateAccountView = () => {
  const { userId } = useParams();
  const { data: user, isLoading } = useUser(userId ?? '');
  const [submitCount, setSubmitCount] = useState(0);
  const [checked, setChecked] = useState(true);

  const [addressModel, setAddressModel] = useState<AddressFormModel>(
    defaultAddressModel(user)
  );
  const [accountNameEnabled, setAccountNameEnabled] = useState(false);
  const [formModel, setFormModel] = useState<AccountFormModel>({
    ...defaultAddressModel(user),
    type: 'Individual',
    accountName: `${user?.name} ${user?.lastName}`,
  });

  const onAddressFormModelChange = useCallback((model: AddressFormModel) => {
    setAddressModel(model);
  }, []);



  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event;
      const value =
        target.type === 'checkbox'
          ? (target as HTMLInputElement).checked
          : target.value;
      const { name } = target;

      setFormModel({
        ...formModel,
        [name]: value,
      });
    },
    [formModel]
  );

  const navigate = useNavigate();
  const { mutate: createAccountMutation, isLoading: isCreateAccountLoading } =
    useMutation(createAccount, {
      onSuccess: () => {
        toaster.success('Success', { description: 'Account Created' });
        navigate(`../user/${userId}/accounts`);
      },
      onError: (err: Error) => {
        toaster.danger('Error creating investment', {
          description: err.message,
        });
      },
    });

  const onEntityFormModelChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { target } = event;
      if (target.value === 'Individual') {
        setFormModel({
          ...formModel,
          type: target.value,
          accountName: `${user?.name} ${user?.lastName}`,
        });
        setAccountNameEnabled(false);
      } else {
        setFormModel({
          ...formModel,
          type: target.value,
          accountName: '',
        });
        setAccountNameEnabled(true);
      }
    },
    [formModel, setFormModel, setAccountNameEnabled, user]
  );

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Pane>
      <SelectField
        name="type"
        onChange={onEntityFormModelChange}
        defaultValue={'Individual'}
        label="Entity Type"
      >
        <option value={'Individual'}> Individual </option>
        <option value={'Entity'}> Entity</option>
      </SelectField>
      <TextInputField
        name="accountName"
        placeholder="Account Owner"
        marginBottom="1rem"
        width="100%"
        label="Account Owner"
        onChange={onInputChange}
        value={formModel.accountName}
        disabled={isLoading || !accountNameEnabled}
        required
        isInvalid={submitCount > 0 && !formModel.accountName.length}
        validationMessage={
          submitCount > 0 && !formModel.accountName.length && 'Required'
        }
      />
      <Heading size={400}>Account address *</Heading>
      <Checkbox
        label="Same as profile"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={isLoading}
      />

      <AddressForm
        user={user}
        isFormDisabled={checked || isLoading}
        isLoading={false}
        onFormModelChange={onAddressFormModelChange}
      />
      <ButtonWithAnalytics
        analyticName="createAccount"
        onClick={() => {
          setSubmitCount(submitCount + 1);
          const { id } = user!
          createAccountMutation({
            data: {
              accountNickname: 'wire',
              accountSource: 'wire',
              country: 'United States',
              streetAddress1: formModel.address,
              state: formModel.state,
              city: formModel.city,
              type: formModel.type as unknown as 'Individual' | 'Entity',
              zip: formModel.zip,
              accountName: formModel.accountName,
            },
            userId: id,
          });
        }}
        appearance="primary"
        disabled={isCreateAccountLoading || !user}
      >
        Create Account
      </ButtonWithAnalytics>
    </Pane>
  );
};
