import { PageContent } from '@netgreen/core-ui';
import { Pane, Pill, Spinner } from 'evergreen-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { AccountsView } from '../views/AccountsView';
import { CreateAccountView } from '../views/CreateAccountView';
import { UserDetailsView } from '../views/UserDetailsView';

export const UserDetails = () => {
  const { userId, view } = useParams();
  const { data: user, isLoading: userLoading } = useUser(userId ?? '');
  const navigate = useNavigate();

  if (userLoading) {
    return <Spinner />;
  }
  return (
    <PageContent pageTitle={user?.name ?? ''}>
      <Pane display="flex" marginBottom="1rem" gap="0.5rem">
        <Pill cursor="pointer" onClick={() => navigate(`/user/${userId}/details`)}>
          Details
        </Pill>
        <Pill cursor="pointer" onClick={() => navigate(`/user/${userId}/accounts`)}>
          Accounts
        </Pill>
      </Pane>
      {view === "details" && <UserDetailsView user={user}/> }
      {view === "accounts" && <AccountsView/> }
      {view === "create-account" && <CreateAccountView/> }
    </PageContent>
  );
};
