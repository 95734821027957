import { environment } from "../../../environments/environment";
import { getOfferingClient } from "../client";
import { Offering, OfferingCategory } from "../models";

const baseUrl = `${environment.apiBaseUrl}/offering`;

export const getOffering = async (offeringId: string): Promise<Offering> => {
  const client = getOfferingClient(baseUrl);
  return client.getOffering(offeringId);
};

export const getOfferings = async (
  category: OfferingCategory
): Promise<Offering[]> => {
  const client = getOfferingClient(baseUrl);
  return client.getOfferings(category);
};
