import { PageContent } from '@netgreen/core-ui';
import { useQuery } from '@tanstack/react-query';
import { Pane, Pill, Spinner, Table } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Offering,
  OfferingCategory,
  getOfferings,
} from '../clients/offering-client';

const useOfferings = (category: OfferingCategory) => {
  return useQuery<Offering[]>([`offering-${category}`], () =>
    getOfferings(category)
  );
};
export const Offerings = () => {
  const [offerings, setOfferings] = useState<Offering[]>([]);
  const [category, setCategory] = useState<OfferingCategory>('open');
  const { data: openOfferings, isLoading: openLoading } = useOfferings('open');
  const { data: closedOfferings, isLoading: closedLoading } =
    useOfferings('closed');
  const { data: unlistedOfferings, isLoading: unlistedLoading } =
    useOfferings('unlisted');
  const navigate = useNavigate();

  useEffect(() => {
    if (category === 'open' && openOfferings) {
      setOfferings(openOfferings);
      return;
    }
    if (category === 'closed' && closedOfferings) {
      setOfferings(closedOfferings);
      return;
    }
    if (category === 'unlisted' && unlistedOfferings) {
      setOfferings(unlistedOfferings);
      return;
    }
    setOfferings([]);
  }, [category, closedOfferings, openOfferings, unlistedOfferings]);

  return (
    <PageContent pageTitle="Offerings">
      <Pane>
        <Pill onClick={() => setCategory('open')}>Open</Pill>
        <Pill onClick={() => setCategory('closed')}>Closed</Pill>
        <Pill onClick={() => setCategory('unlisted')}>Unlisted</Pill>
      </Pane>

      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Offering</Table.TextHeaderCell>
          <Table.TextHeaderCell>Category</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {category === 'open' && openLoading && <Spinner />}
          {category === 'closed' && closedLoading && <Spinner />}
          {category === 'unlisted' && unlistedLoading && <Spinner />}
          {offerings.map((offering) => (
            <Table.Row
              key={offering.id}
              onClick={() => navigate(`/offering/${offering.id}`)}
            >
              <Table.TextCell>{offering.name}</Table.TextCell>
              <Table.TextCell>{offering.category}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </PageContent>
  );
};
