import { setupAmplify } from '@netgreen/auth';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './components/authenticated-route/auth';
import { Navbar } from './components/navbar';
import { AttachDocusign } from './pages/AttachDocusign';
import { CreateInvestment } from './pages/CreateInvestment';
import Home from './pages/Home';
import Login from './pages/Login';
import { OfferingDetails } from './pages/OfferingDetails';
import { Offerings } from './pages/Offerings';
import { UserDetails } from './pages/UserDetails';
import { Users } from './pages/Users';

export default function App() {
  setupAmplify();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Navbar />
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offerings"
        element={
          <ProtectedRoute>
            <Navbar />
            <Offerings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offering/:offeringId"
        element={
          <ProtectedRoute>
            <Navbar />
            <OfferingDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/offering/:offeringId/create-trade"
        element={
          <ProtectedRoute>
            <Navbar />
            <CreateInvestment />
          </ProtectedRoute>
        }
      />

      <Route
        path="/offering/:offeringId/attach-docusign"
        element={
          <ProtectedRoute>
            <Navbar />
            <AttachDocusign />
          </ProtectedRoute>
        }
      />

      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <Navbar />
            <Users />
          </ProtectedRoute>
        }
      />

      <Route
        path="/user/:userId/:view"
        element={
          <ProtectedRoute>
            <Navbar />
            <UserDetails />
          </ProtectedRoute>
        }
      />

      <Route path="/login" element={<Login />} />
    </Routes>
  );
}
