import { ButtonWithAnalytics } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import {
  DocumentOpenIcon,
  EmptyState,
  IconButton,
  Pane,
  SearchIcon,
  Spinner,
  Table,
  TrashIcon,
  toaster,
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { deleteDocusignFromOffering } from '../clients/admin-client';
import { Offering } from '../clients/offering-client';
import { useOfferingDocusignDocuments } from '../hooks';

export const DocusignView = ({ offering }: { offering?: Offering }) => {
  const { data, isLoading, refetch } = useOfferingDocusignDocuments(offering?.id ?? '');
  const navigate = useNavigate();

  const {
    mutate: deleteDocusignMutation,
    isLoading: isDeleteInProgress,
  } = useMutation(deleteDocusignFromOffering, {
    onSuccess: () => {
      refetch();
      toaster.danger('Success', { description: 'Docusign deleted' });
    },
    onError: (err: Error) => {
      toaster.danger('Error creating investment', { description: err.message });
    },
  });
  return (
    <>
      <Pane marginBottom="1rem">
        <ButtonWithAnalytics
          analyticName="attachDocusign"
          onClick={() => navigate('attach-docusign')}
          appearance="primary"
        >
          Attach Docusign
        </ButtonWithAnalytics>
      </Pane>

      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>View Document</Table.TextHeaderCell>
          <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {isLoading && (
            <Pane
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="10rem"
            >
              <Spinner />
            </Pane>
          )}
          {!isLoading && (!data || (data && data.documents.length === 0)) && (
            <EmptyState
              background="light"
              title="No documents attached to offering"
              orientation="horizontal"
              icon={<SearchIcon color="#C1C4D6" />}
              iconBgColor="#EDEFF5"
            />
          )}
          {!isLoading &&
            data &&
            data.documents?.map((document) => (
              <Table.Row id={document.templateId}>
                <Table.TextCell>{document.templateName}</Table.TextCell>
                <Table.TextCell>
                  <a
                    href={document.templateUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DocumentOpenIcon>View</DocumentOpenIcon>
                  </a>
                </Table.TextCell>
                <Table.TextCell>
                <IconButton
                      icon={<TrashIcon color="danger"/>}
                      disabled={isDeleteInProgress || !offering}
                      border="none"
                      onClick={() =>{deleteDocusignMutation({
                        offeringId: offering?.id ?? '',
                        templateId: document.templateId
                      })}}
                    />
  
                </Table.TextCell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
};
