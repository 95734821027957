import { useTheme } from '@emotion/react';
import { ButtonWithAnalytics, PageContent } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import { Alert, Pane, SelectField, Spinner, toaster } from 'evergreen-ui';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { attachDocusignToOffering } from '../clients/admin-client';
import { useDocusignDocuments } from '../hooks';

export const AttachDocusign = () => {
  const { offeringId } = useParams();
  const theme = useTheme();
  const { data: documents, isLoading: isDocumentsLoading } =
    useDocusignDocuments();

  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const navigate = useNavigate();
  const { mutate: attachDocusignMutation, isLoading: isAttachInProgress } =
    useMutation(attachDocusignToOffering, {
      onSuccess: () => {
        toaster.success('Success', { description: 'Docusign Attached' });
        navigate(`../offering/${offeringId}`);
      },
      onError: (err: Error) => {
        toaster.danger('Error creating investment', {
          description: err.message,
        });
      },
    });


  const {
    handleSubmit,
    handleChange: onInputChange,
    handleBlur,
    errors,
    submitCount,
  } = useFormik({
    initialValues: { templateId: '' },
    onSubmit: (formValues) => {
      if (!formValues.templateId) {
        setErrorMessage({
          title: 'Missing information',
          message: 'Please select a document.',
        });
        return;
      }
      attachDocusignMutation({
        templateId: formValues.templateId,
        templateName:
          documents?.documents?.find(
            (d: any) => d.templateId === formValues.templateId
          )?.name ?? '',
        offeringId: offeringId ?? '',
      });
    },
  });

  if (isDocumentsLoading) {
    return (
      <Pane
        height="50vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Pane>
    );
  }

  const hasErrors = submitCount > 0 && Object.keys(errors).length > 0;
  return (
    <PageContent pageTitle={'Attach Docusign'}>
      {(hasErrors || errorMessage) && (
        <Alert
          intent="danger"
          title={errorMessage ? errorMessage.title : 'Missing information'}
          marginBottom="1rem"
          marginTop="1rem"
        >
          {errorMessage
            ? errorMessage?.message
            : 'Please fill out the required fields.'}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Pane>
          <SelectField
            name="templateId"
            onChange={onInputChange}
            defaultValue={''}
            label="Template"
            disabled={isAttachInProgress}
            onBlur={handleBlur}
          >
            <option disabled value={''}>
              {' '}
              -- select a document --{' '}
            </option>{' '}
            {documents?.documents?.map((doc) => (
              <option key={doc.templateId} value={doc.templateId}>
                {doc.name}
              </option>
            ))}
          </SelectField>
        </Pane>
        <ButtonWithAnalytics
          type="submit"
          analyticName="adminCreateTradeBtn"
          isLoading={isAttachInProgress}
          appearance="primary"
        >
          Attach Document
        </ButtonWithAnalytics>
      </form>
    </PageContent>
  );
};
