import { Hub } from "@aws-amplify/core";
import { useCallback, useEffect, useState } from "react";

export const useLastAuthEvent = () => {
  const [lastEvent, setLastEvent] = useState("");

  const listener = useCallback((data: any) => {
    setLastEvent(data.payload.event);
  }, []);

  useEffect(() => {
    Hub.listen("auth", listener);

    return () => {
      Hub.remove("auth", listener);
    };
  }, [listener]);

  return lastEvent;
};
