import { User } from '@netgreen/clients';
import { Table } from 'evergreen-ui';

export const UserDetailsView =  ({ user }: { user?: User }) => {
  if (!user) {
    return null;
  }
  return (
    <Table>
      <Table.Body>
        {user &&
          Object.entries(user).map(([key, value]) => {
            if (key === 'accounts' || key === 'details') {
              return null;
            }
            return (
              <Table.Row key={key}>
                <Table.TextCell>{key}</Table.TextCell>
                <Table.TextCell>{String(value)}</Table.TextCell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};
