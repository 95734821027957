import React from "react";
import { Navigate } from "react-router-dom";
import { useIsLoggedIn } from "../auth/useIsLoggedIn";

export const ProtectedRoute = ({ children }: { children: any }) => {
  const { isLoggedIn, isLoading } = useIsLoggedIn();
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoading && !isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return children;
};
