import { useTheme } from '@emotion/react';
import { ButtonWithAnalytics, PageContent } from '@netgreen/core-ui';
import { useMutation } from '@tanstack/react-query';
import {
  Alert,
  Heading,
  Pane,
  SelectField,
  Spinner,
  Text,
  TextInputField,
  toaster,
} from 'evergreen-ui';
import { useFormik } from 'formik';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { createTradeForUser } from '../clients/admin-client';
import { useOffering } from '../hooks';
import { useUser } from '../hooks/useUser';
import { useUsers } from '../hooks/useUsers';

const tradeTypes = ['ACH', 'WIRE', 'CHECK', 'CREDITCARD', 'TBD', 'IRA'];
interface CreateTradeModel {
  amount: number | undefined;
  tradeType: string | undefined;
  user: string | undefined;
  accountId: string | undefined;
}

const schema = yup.object({
  amount: yup.number().required('amount is required'),
  tradeType: yup.string().required('tradeType is required'),
  user: yup.string().required('user is required'),
  accountId: yup.string().required('accountId is required'),
});

const defaultModel: CreateTradeModel = {
  amount: undefined,
  tradeType: 'wire',
  user: undefined,
  accountId: undefined,
};

export const CreateInvestment = () => {
  const { offeringId } = useParams();
  const theme = useTheme();

  const { data: users, isLoading: isUsersLoading } = useUsers();
  const { data: offering, isLoading: isOfferingLoading } = useOffering(
    offeringId ?? ''
  );

  const [errorMessage, setErrorMessage] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const navigate = useNavigate();
  const {
    mutate: createInvestmentMutation,
    isLoading: isCreateInvestmentInProgress,
  } = useMutation(createTradeForUser, {
    onSuccess: () => {
      toaster.success('Success', { description: 'Investment created' });
      navigate(`../offering/${offeringId}`);
    },
    onError: (err: Error) => {
      toaster.danger('Error creating investment', { description: err.message });
    },
  });

  const {
    handleSubmit,
    handleChange: onInputChange,
    handleBlur,
    touched,
    errors,
    submitCount,
    setFieldValue,
    values,
  } = useFormik({
    initialValues: defaultModel,
    validationSchema: schema,
    onSubmit: (formValues) => {
      createInvestmentMutation({
        data: {
          userId: formValues.user ?? '',
          accountId: formValues.accountId ?? '',
          transactionType: formValues.tradeType ?? '',
          transactionUnits: formValues.amount ?? 0,
        },
        offeringId: offeringId ?? '',
      });
    },
  });

  const { data: user } = useUser(values.user);

  if (isUsersLoading || isOfferingLoading) {
    return (
      <Pane
        height="50vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Pane>
    );
  }

  const hasErrors = submitCount > 0 && Object.keys(errors).length > 0;
  const submittedOnce = submitCount > 0;

  const sortedUsers = users?.users.sort((a, b) =>
    a.email.localeCompare(b.email)
  );

  const totalAmount =
    (values.amount ?? 0) * (offering?.transactionFeeMultiple ?? 1);
  return (
    <PageContent pageTitle={'Create Investment'}>
      {(hasErrors || errorMessage) && (
        <Alert
          intent="danger"
          title={errorMessage ? errorMessage.title : 'Missing information'}
          marginBottom="1rem"
          marginTop="1rem"
        >
          {errorMessage
            ? errorMessage?.message
            : 'Please fill out the required fields.'}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Pane>
          <SelectField
            name="user"
            onChange={onInputChange}
            defaultValue={'default'}
            label="User"
            disabled={isCreateInvestmentInProgress}
            onBlur={handleBlur}
            validationMessage={submittedOnce && touched.user && errors.user}
          >
            <option disabled value={'default'}>
              {' '}
              -- select an option --{' '}
            </option>{' '}
            {sortedUsers?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.email}
              </option>
            ))}
          </SelectField>
        </Pane>
        <Pane>
          <SelectField
            name="accountId"
            onChange={onInputChange}
            defaultValue={'default'}
            label="Account"
            disabled={isCreateInvestmentInProgress}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce && touched.accountId && errors.accountId
            }
          >
            <option disabled value={'default'}>
              -- select an option --
            </option>
            {user?.accounts?.map((account) => (
              <option key={account.accountId} value={account.accountId}>
                {account.accountName} | {account.accountNickName} |{' '}
                {account.accountId}
              </option>
            ))}
          </SelectField>
        </Pane>
        <Pane>
          <SelectField
            name="tradeType"
            onChange={onInputChange}
            defaultValue={'default'}
            label="Trade Type"
            disabled={isCreateInvestmentInProgress}
            onBlur={handleBlur}
            validationMessage={
              submittedOnce && touched.tradeType && errors.tradeType
            }
          >
            <option disabled value={'default'}>
              -- select an option --
            </option>
            {tradeTypes.map((tradeType) => (
              <option key={tradeType} value={tradeType.toUpperCase()}>
                {tradeType.toUpperCase()}
              </option>
            ))}
          </SelectField>
        </Pane>

        <NumericFormat
          customInput={TextInputField}
          name="amount"
          type="text"
          placeholder="Enter amount in USD"
          width="100%"
          label="Investment Amount"
          disabled={isCreateInvestmentInProgress}
          allowNegative={false}
          valueIsNumericString
          onValueChange={({ floatValue }) => {
            setFieldValue('amount', floatValue);
          }}
          prefix="$"
          decimalScale={0}
          fixedDecimalScale
          thousandSeparator=","
          max={5000000} // 5k
          defaultValue={0}
          onBlur={handleBlur}
          validationMessage={submittedOnce && touched.amount && errors.amount}
        />
        <Pane marginBottom="1rem">
          <Heading size={400} color={theme.textColor}>
            Transaction Fee
          </Heading>
          <Text>${totalAmount.toLocaleString()}</Text>
        </Pane>
        <ButtonWithAnalytics
          type="submit"
          analyticName="adminCreateTradeBtn"
          isLoading={isCreateInvestmentInProgress}
          appearance="primary"
        >
          Create Investment Record
        </ButtonWithAnalytics>
      </form>
    </PageContent>
  );
};
