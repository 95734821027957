import { ButtonWithAnalytics } from '@netgreen/core-ui';
import {
  Pane,
  Spinner,
  Table,
  TextTableCell,
  TextTableHeaderCell,
  TickCircleIcon,
} from 'evergreen-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserAccounts } from '../hooks';

export const AccountsView = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { data: user, isLoading: accountsLoading } = useUserAccounts(
    userId ?? ''
  );
  return (
    <Table marginBottom="1rem">
      <Pane marginBottom="1rem">
        <ButtonWithAnalytics
          marginRight="auto"
          analyticName="createAccount"
          onClick={() => navigate(`/user/${userId}/create-account`)}
          appearance="primary"
        >
          Create Wire Account
        </ButtonWithAnalytics>
      </Pane>
      <Table.Head>
        <TextTableHeaderCell>Account Name</TextTableHeaderCell>
        <TextTableHeaderCell>Account Number</TextTableHeaderCell>
        <TextTableHeaderCell>Account ID</TextTableHeaderCell>
        <TextTableHeaderCell>Status</TextTableHeaderCell>
      </Table.Head>
      <Table.Body>
        {accountsLoading && (
          <Pane display="flex" justifyContent=" center" marginY="2rem">
            <Spinner />
          </Pane>
        )}
        {!accountsLoading && (
          <>
            {user?.accounts.map((account) => (
              <Table.Row key={account.createdDate}>
                <TextTableCell>
                  <Pane>{account.accountName}</Pane>
                  <Pane>{account.accountNickName}</Pane>
                </TextTableCell>
                <TextTableCell>
                  {account.accountSource === 'external' && (
                    <>
                      ****{' '}
                      {account.accountNumber?.slice(
                        account.accountNumber.length - 5,
                        account.accountNumber.length - 1
                      )}
                    </>
                  )}
                  {account.accountSource === 'pending' && (
                    <>Pending User Setup.</>
                  )}
                  {account.accountSource === 'wire' && <>Wire Account</>}
                </TextTableCell>
                <TextTableCell>
                  {account.accountId}
                </TextTableCell>
                <TextTableCell>
                  {account.accountSource === 'pending' ? (
                    <TickCircleIcon color="warning" marginRight={16} />
                  ) : (
                    <TickCircleIcon color="success" marginRight={16} />
                  )}
                </TextTableCell>
              </Table.Row>
            ))}
            {user?.accounts.length === 0 && (
              <Table.Row>
                <Table.Cell>
                  No bank accounts or cards have been linked.
                </Table.Cell>
              </Table.Row>
            )}
          </>
        )}
      </Table.Body>
    </Table>
  );
};
