import { useQuery } from "@tanstack/react-query";
import { IAdminOfferingTrades, getTradesForOffering } from "../clients/admin-client";

export const useTrades = (offeringId: string) => {
    return useQuery<IAdminOfferingTrades[]>(
      [`trades-${offeringId}`],
      () => getTradesForOffering(offeringId),
      {
        cacheTime: 60 * 5 * 1000, // 5 minutes
      }
    );
  };
  
  