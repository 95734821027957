import { BaseClient } from '../../base-client';
import {
  IAdminGetUserResponse,
  IAdminGetUsersResponse,
  IAdminOfferingGetUsersResponse,
  IAdminOfferingTrades,
  ICreateAccountRequest,
  ICreateTradeForUserParams,
  IGetDocumentsResponse,
  IGetOfferingDocumentsResponse,
  IUserAccount
} from '../models';

interface IAdminClient {
  getTradesForOffering: (offeringId: string) => Promise<unknown>;
  createAccount: (account: ICreateAccountRequest,  userId: string) => Promise<IUserAccount>;
}

class AdminClient extends BaseClient implements IAdminClient {
  constructor(private baseUrl: string) {
    super(baseUrl);
  }

  async getTradesForOffering(
    offeringId: string
  ): Promise<IAdminOfferingTrades[]> {
    const authHeaders = await this.getAuthHeaders();

    const response = (
      await this.httpClient.get(`/v1/offering/${offeringId}/trades`, {
        headers: { ...authHeaders },
      })
    ).data;

    return response;
  }

  async cancelTradeForUser(userId: string, tradeId: string, reason: string) {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.delete(
        `/v1/trade/${userId}/${tradeId}?reason=${reason}`,
        {
          headers: { ...authHeaders },
        }
      )
    ).data;

    return response;
  }

  async getOfferingUsers(
    offeringId: string
  ): Promise<IAdminOfferingGetUsersResponse> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.get(`/v1/offering/${offeringId}/users`, {
        headers: { ...authHeaders },
      })
    ).data;
    return response;
  }

  async getUsers(): Promise<IAdminGetUsersResponse> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.get(`/v1/users`, {
        headers: { ...authHeaders },
      })
    ).data;
    return response;
  }

  async getUser(userId: string): Promise<IAdminGetUserResponse> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.get(`/v1/user/${userId}`, {
        headers: { ...authHeaders },
      })
    ).data;
    return response;
  }

  // v1/offering/{offeringId}/trades
  async createTradeForUser(
    offeringId: string,
    data: ICreateTradeForUserParams
  ) {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.post(
        `/v1/offering/${offeringId}/trades`,
        data,
        {
          headers: { ...authHeaders },
        }
      )
    ).data;
    return response;
  }

  async createAccount(account: ICreateAccountRequest, userId: string): Promise<IUserAccount> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.post(
        `/v1/user/${userId}/accounts`,
        account,
        {
          headers: { ...authHeaders },
        }
      )
    ).data;
    return response;
  }

  async getDocusignDocumentsForOffering(offeringId: string): Promise<IGetOfferingDocumentsResponse> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.get(
        `/v1/offering/${offeringId}/documents`,
        {
          headers: { ...authHeaders },
        }
      )
    ).data;
    return response;
  }

  async getDocusignDocuments(): Promise<IGetDocumentsResponse> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.get(
        `/v1/documents`,
        {
          headers: { ...authHeaders },
        }
      )
    ).data;
    return response;
  }
  async attachDocusignToOffering({
    offeringId,
    templateId,
    templateName
  }: {
    offeringId: string;
    templateId: string;
    templateName: string;
  }): Promise<void> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.post(
        `/v1/offering/${offeringId}/documents`,
        {
          templateId,
          templateName
        },
        {
          headers: { ...authHeaders },
        }
      )
    ).data;
    return response;
  }

  async deleteDocusignFromOffering({
    templateId,
    offeringId
  }: {
    templateId: string;
    offeringId: string;
  }): Promise<void> {
    const authHeaders = await this.getAuthHeaders();
    const response = (
      await this.httpClient.delete(
        `/v1/offering/${offeringId}/documents/${templateId}`,
        {
          headers: { ...authHeaders },
        }
      )
    ).data;
    return response;
  }
}
export const getAdminClient = (baseUrl: string) => new AdminClient(baseUrl);
