import {
  Icon,
  Image,
  Link,
  LinkIcon,
  Pane,
  Table,
  Text,
} from "evergreen-ui";
import { Media, Offering } from "../clients/offering-client";

export const DetailView = ({ offering }: { offering?: Offering }) => {
    const getMediaTableItem = (media: Media, height: string) => {
      switch (true) {
        case media.mime.toLowerCase().startsWith("image"):
          return <Image src={media.url} marginRight="1rem" height={height} />;
        case media.mime.toLowerCase().startsWith("video"):
          // eslint-disable-next-line jsx-a11y/media-has-caption
          return (
            <Pane marginRight="1rem">
              <video height={height} controls>
                <source src={media.url} type={media.mime} />
                Your browser does not support the video tag.
              </video>
            </Pane>
          );
        default:
          return null;
      }
    };
    return (
      <Table>
        <Table.Body>
          {offering &&
            Object.entries(offering).map(([key, value]) => {
              if (Array.isArray(value)) {
                switch (key) {
                  case "documents": {
                    return (
                      <Table.Row key={key}>
                        <Table.TextCell>{key}</Table.TextCell>
                        <Table.TextCell>
                          {value &&
                            (value as Media[])?.map((doc) => {
                              const docNameWithExtension = doc.url.split("/");
                              const docNameWithoutExtension =
                                docNameWithExtension[
                                  docNameWithExtension.length - 1
                                ].split(".")[0];
                              return (
                                <Pane>
                                  <Link
                                    is="a"
                                    download
                                    href={doc.url}
                                    target="_blank"
                                  >
                                    <Icon
                                      icon={LinkIcon}
                                      size={12}
                                      marginRight="0.5rem"
                                    />
                                    <Text>{docNameWithoutExtension}</Text>
                                  </Link>
                                </Pane>
                              );
                            })}
                        </Table.TextCell>
                      </Table.Row>
                    );
                  }
                  case "marketing_media":
                    return (
                      <Table.Row key={key} height="10rem">
                        <Table.TextCell>{key}</Table.TextCell>
                        <Table.TextCell>
                          {value &&
                            (value as Media[])?.map((media) =>
                              getMediaTableItem(media, "10rem")
                            )}
                        </Table.TextCell>
                      </Table.Row>
                    );
                  default: {
                    return null;
                  }
                }
              }
              if (key === "icon" || key === "cover_image") {
                return (
                  <Table.Row key={key} height={value ? "10rem" : "4rem"}>
                    <Table.TextCell>{key}</Table.TextCell>
                    <Table.TextCell>
                      {value && <Image src={String(value)} height="10rem" />}
                    </Table.TextCell>
                  </Table.Row>
                );
              }
              return (
                <Table.Row key={key}>
                  <Table.TextCell>{key}</Table.TextCell>
                  <Table.TextCell>{String(value)}</Table.TextCell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  };
  