import { signOut, useCurrentCognitoUser } from "@netgreen/auth";
import { PageContent } from "@netgreen/core-ui";
import {
  Button,
  Heading,
  Link,
  ListItem,
  Pane,
  Text,
  UnorderedList,
} from "evergreen-ui";
import { Link as ReactLink } from "react-router-dom";
import { environment } from "../environments/environment";

const Home = () => {
  const { data: user, isLoading } = useCurrentCognitoUser();
  return (
    <PageContent pageTitle="Home" hideTitle>
      <Pane>
        {!isLoading && user && (
          <Heading marginBottom="1rem">Hello, {user?.name}</Heading>
        )}
      </Pane>
      <Pane>
        <Text marginBottom="1rem">API URL: {environment.apiBaseUrl}</Text>
      </Pane>
      <Pane marginBottom="1rem">
        <Text>Helpful Links:</Text>
        {!environment.production && (
          <UnorderedList>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://mixpanel.com/project/2918233/view/3445957/app/boards#id=5243596"
                target="_blank"
              >
                MixPanel Session Tracking
              </Link>
            </ListItem>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://analytics.google.com/analytics/web/#/p359132887/reports/intelligenthome"
                target="_blank"
              >
                Google Analytics
              </Link>
            </ListItem>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://strapi.dev.netgreencapital.com/admin"
                target="_blank"
              >
                Strapi Admin Panel
              </Link>
            </ListItem>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://api-sandboxdash.norcapsecurities.com/admin_v3/login"
                target="_blank"
              >
                North Capital Admin Panel
              </Link>
            </ListItem>
          </UnorderedList>
        )}
        {environment.production && (
          <UnorderedList>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://mixpanel.com/project/3054588/view/3570836/app/boards#discover"
                target="_blank"
              >
                MixPanel Session Tracking
              </Link>
            </ListItem>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://analytics.google.com/analytics/web/#/p359149715/reports/intelligenthome"
                target="_blank"
              >
                Google Analytics
              </Link>
            </ListItem>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://strapi.netgreen.com/admin"
                target="_blank"
              >
                Strapi Admin Panel
              </Link>
            </ListItem>
            <ListItem>
              <Link
                is={ReactLink}
                to="https://api.norcapsecurities.com/admin_v3/"
                target="_blank"
              >
                North Capital Admin Panel
              </Link>
            </ListItem>
          </UnorderedList>
        )}
      </Pane>
      <Button onClick={() => signOut()}>Logout</Button>
    </PageContent>
  );
};
export default Home;
