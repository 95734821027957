import { Badge, Spinner, Table, Text } from "evergreen-ui";
import { Offering } from "../clients/offering-client";
import { useOfferingUsers } from "../hooks";

export const UserView = ({ offering }: { offering?: Offering }) => {
  const { data, isLoading } = useOfferingUsers(offering?.id ?? "");
  const uniqueIds = data
    ? [
        ...new Set([
          ...data.issuers.map((user) => user.id),
          ...data.promoters.map((user) => user.id),
        ]),
      ]
    : [];
  const uniqueUsers = uniqueIds.map((id) => {
    return (
      data?.issuers?.find((user) => user.id === id) ??
      data?.promoters?.find((user) => user.id === id)
    );
  });

  return (
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>UserID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Email</Table.TextHeaderCell>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {isLoading && <Spinner />}
        {!isLoading && data && uniqueUsers.length === 0 && (
          <Text>No users associated to offering</Text>
        )}
        {!isLoading &&
          data &&
          uniqueUsers &&
          uniqueUsers.map((resp) => (
            <Table.Row key={resp?.id}>
              <Table.TextCell>{resp?.id}</Table.TextCell>
              <Table.TextCell>{resp?.email}</Table.TextCell>
              <Table.TextCell>
                {resp?.name} {resp?.lastName}
              </Table.TextCell>
              <Table.TextCell>
                {data?.issuers?.find((user) => user.id === resp?.id) ? (
                  <Badge color="neutral" fontSize={10}>
                    Issuer
                  </Badge>
                ) : null}
                {data?.promoters?.find((user) => user.id === resp?.id) ? (
                  <Badge color="purple" fontSize={10}>
                    Promoter
                  </Badge>
                ) : null}
              </Table.TextCell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
