import {
  PageContent,
} from "@netgreen/core-ui";
import {
  Pane,
  Pill,
  Spinner,
} from "evergreen-ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useOffering } from "../hooks";
import { DetailView, DocusignView, InvestmentView, UserView } from "../views";


export const OfferingDetails = () => {
  const { offeringId } = useParams();
  const { data: offering, isLoading: offeringLoading } = useOffering(
    offeringId ?? ""
  );
  const [view, setView] = useState<"details" | "investment" | "users" | "docusign">(
    "details"
  );

  if (offeringLoading) {
    return <Spinner />;
  }
  return (
    <PageContent pageTitle={offering?.name ?? ""}>
      <Pane display="flex" marginBottom="1rem" gap="0.5rem">
        <Pill cursor="pointer" onClick={() => setView("details")}>Details</Pill>
        <Pill cursor="pointer" onClick={() => setView("investment")}>Investments</Pill>
        <Pill cursor="pointer" onClick={() => setView("users")}>Users</Pill>
        <Pill cursor="pointer" onClick={() => setView("docusign")}>Docusign</Pill>
      </Pane>
      <Pane >
        {view === "details" && <DetailView offering={offering} />}
        {view === "investment" && <InvestmentView offering={offering} />}
        {view === "users" && <UserView offering={offering} />}
        {view === "docusign" && <DocusignView offering={offering} />}
      </Pane>
    </PageContent>
  );
};





