import { Netgreen_Acorn as Logo } from "@netgreen/assets";
import { isHandledAuthError, signIn } from "@netgreen/auth";
import { ButtonWithAnalytics, PageContent } from "@netgreen/core-ui";
import { useMutation } from "@tanstack/react-query";
import {
  Heading,
  Image,
  InlineAlert,
  Pane,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "../components/auth/useIsLoggedIn";
interface LoginFormModel {
  email: string;
  password: string;
}

const defaultModel: LoginFormModel = {
  email: "",
  password: "",
};

const Login = () => {
  const [formModel, setFormModel] = useState<LoginFormModel>(defaultModel);
  const [missingInput, setMissingInput] = useState<string | undefined>(
    undefined
  );

  const { isLoggedIn } = useIsLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      toaster.success("You are already logged in!");
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleAuthErrors = useCallback((error: Error) => {
    if (isHandledAuthError(error)) {
      setMissingInput(error.message);
    } else {
      setMissingInput("Something went wrong. Please try again.");
    }
  }, []);

  const { mutate, isLoading } = useMutation(signIn, {
    onSuccess: () => {
      navigate("/");
    },
    onError: handleAuthErrors,
  });

  const login = useCallback(() => {
    setMissingInput(undefined);

    if (!formModel.email.length || !formModel.password.length) {
      setMissingInput("Please fill out all of the required fields");
      return;
    }

    mutate({
      email: formModel.email,
      password: formModel.password,
    });
  }, [formModel.email, formModel.password, mutate]);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event;
      const value =
        target.type === "checkbox"
          ? (target as HTMLInputElement).checked
          : target.value;
      const { name } = target;

      setFormModel({
        ...formModel,
        [name]: value,
      });
    },
    [formModel]
  );

  return (
    <PageContent pageTitle="Login" hideTitle noXMargin>
      <Pane
        display="flex"
        flexDirection="column"
        maxWidth="25rem"
        margin="auto"
      >
        <Image
          src={Logo}
          width="3.25rem"
          marginBottom="0.5rem"
          alignSelf="center"
        />
        <Heading size={900} alignSelf="center" marginBottom="1.5rem">
          Log In
        </Heading>

        {missingInput && (
          <Pane marginBottom="1rem">
            <InlineAlert intent="danger">{missingInput}</InlineAlert>
          </Pane>
        )}
        <form>
          <TextInputField
            name="email"
            placeholder="email"
            marginBottom="1rem"
            width="100%"
            label="Email"
            onChange={onInputChange}
            value={formModel.email}
            disabled={isLoading}
          />
          <TextInputField
            name="password"
            placeholder="password"
            marginBottom="1rem"
            width="100%"
            label="Password"
            type="password"
            onChange={onInputChange}
            value={formModel.password}
            disabled={isLoading}
          />
        </form>

        <ButtonWithAnalytics
          analyticName="LoginSubmitBtn"
          appearance="primary"
          marginBottom="0.5rem"
          marginTop="0.5rem"
          onClick={login}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Login
        </ButtonWithAnalytics>
      </Pane>
    </PageContent>
  );
};
export default Login;
